import React from "react";
import "./index.scss";

class TeamsShowcase2 extends React.Component {
  state = {
    highlights: [
      {
        title:
          "Rayscape (ex XVision), an AI-based platform aiding radiologists in analyzing medical images, has raised a total of 2M€ through multiple investment rounds.",
        link: "https://www.google.com/url?q=https://www.startupcafe.ro/idei-antreprenori/finantare-startup-rayscape-castigator-accelerator-afaceri-rubik-garage.htm&sa=D&source=editors&ust=1731704249676837&usg=AOvVaw3708PkPoBngZT3vSGqAtui",
      },
      {
        title:
          "OxidOS joins industry leaders to launch the Safety-Critical Rust Consortium, promoting responsible Rust use in safety-critical software systems.",
        link: "https://www.google.com/url?q=https://siliconangle.com/2024/06/12/rust-foundation-industry-leaders-launch-safety-critical-rust-consortium/&sa=D&source=editors&ust=1731704249679010&usg=AOvVaw1Jsu4TESFkNi1ldv_AQfhi",
      },
      {
        title:
          "Coda Intelligence, cybersecurity startup, was acquired by a U.S. provider of software asset management solutions in a landmark deal exceeding 20M€.",
        link: "https://www.google.com/url?q=https://www.forbes.ro/compania-americana-pdq-achizitioneaza-start-up-ul-local-de-cybersecurity-coda-intelligence-cu-peste-20-de-milioane-de-euro-389733&sa=D&source=editors&ust=1731704249677681&usg=AOvVaw2Wjg7Y3Ey4pBa7ur60iQLX",
      },
      {
        title:
          "Veridion (ex Soleadify) secured 6M$ in funding aiming to expand into the US market and develop its own AI technology. ",
        link: "https://www.google.com/url?q=https://www.startupcafe.ro/idei-antreprenori/finantare-milioane-dolari-startup-romanesc-veridion.htm&sa=D&source=editors&ust=1731704249679589&usg=AOvVaw2--YpQuOGx6oSAyM0hLmos",
      },
      {
        title:
          "Pentest-Tools earns a spot on the Deloitte EMEA Technology Fast 500 with this year's average growth rate among featured companies hitting 1,931%.",
        link: "https://www.google.com/url?q=https://www.forbes.ro/opt-companii-romanesti-incluse-in-cea-mai-recenta-editie-a-deloitte-emea-technology-fast-500-401136&sa=D&source=editors&ust=1731704249678445&usg=AOvVaw37rbGbR6mEgRTRrd6z6oew",
      },
      {
        title:
          "VatisTech, audio-video processing platform, secured 850K€ in investments and launched new transcription models for sectors like medical, legal, and call centers.",
        link: "https://www.google.com/url?q=https://www.zf.ro/business-hi-tech/vatis-tech-platforma-procesarea-continutului-audio-video-obtinut-21490554&sa=D&source=editors&ust=1731704249680283&usg=AOvVaw1HwZ7UQq9WMgTltdmjo67P",
      },
    ],
  };
  render() {
    let highlightsList = this.state.highlights.map((e, index) => {
      // //id = "last-box"
      if (index === 5) {
        return (
          <div className="big-box">
            <p>
              {" "}
              {e.title}{" "}
              <span>
                {" "}
                <a target="_blank" className="more-details" href={e.link}>
                  {" "}
                  More Details{" "}
                </a>{" "}
              </span>{" "}
            </p>
          </div>
        );
      }
      return (
        <div className="big-box">
          <p>
            {" "}
            {e.title}{" "}
            <span>
              {" "}
              <a target="_blank" className="more-details" href={e.link}>
                {" "}
                More Details{" "}
              </a>{" "}
            </span>{" "}
          </p>
        </div>
      );
    });
    return (
      <div className="TeamsShowcase2">
        <h2> Latest Alumni Highlights </h2>
        <div className="list-big">{highlightsList}</div>
      </div>
    );
  }
}

export default TeamsShowcase2;

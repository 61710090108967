/**
 * Schedule component
 *
 * Displays date and time for events
 */

import React, { Component } from "react";
import PropTypes from "prop-types";

import CitySelector from "components/Common/CitySelector";

import "./index.scss";

import register from "./register.svg";
import hackathon from "./hackathon.svg";
import demoday from "./demoday.svg";

const propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      date: PropTypes.string,
      city: PropTypes.string,
    }),
  ),
  city: PropTypes.string,
};
const defaultProps = {
  data: [],
  city: "",
};

function unique(list) {
  return list.filter((x, i) => list.indexOf(x) === i);
}

class Schedule extends Component {
  constructor(props) {
    super(props);

    this.state = {
      city: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.city !== "") {
      this.setState({ ...this.state, city: nextProps.city });
    } else if (nextProps.data.length > 0) {
      this.setState({ ...this.state, city: nextProps.data[0].city });
    }
  }

  select = (city) => this.setState({ ...this.state, city });

  get cities() {
    let { data } = this.props;

    return unique(data.map((x) => x.city));
  }

  get selector() {
    let { city } = this.state;
    let { data } = this.props;

    return (
      <CitySelector
        options={data.map((x) => x.city)}
        selected={city}
        onClick={this.select}
      />
    );
  }

  get dates() {
    let { city } = this.state;
    let { data } = this.props;

    return data
      .filter((x) => x.city === city)
      .map((x, i) => this.getDate(x, i));
  }

  getDate(data, index, isOver = false) {
    let { title, date } = data;
    let image = "";

    if (title.includes("register")) {
      image = register;
    } else if (title.includes("hackathon")) {
      image = hackathon;
    } else if (title.includes("demo")) {
      image = demoday;
    }

    return (
      <div key={index} className={"date" + (isOver ? " over" : "")}>
        <img src={image} alt="" />
        <h1>
          {title}
          <span>{date}</span>
        </h1>
      </div>
    );
  }

  render() {
    // if (this.props.data.length === 0) {
    //   return null
    // }

    return (
      <div className="Schedule">
        <div className="title">
          {/* <h1> The when and what </h1> */}
          {/* {this.selector} */}
        </div>
        <a
          style={{ width: "100%", textAlign: "center" }}
          href="https://docs.google.com/presentation/d/1pp8wcKZb99VLG9NU2zVVhmowj3XF8z7T0K1uHAaU5PI/edit#slide=id.g2b24b0dd382_0_486"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="text challenges">
            <h2>
              Do you have a great team, but you are still searching for the
              idea?
            </h2>
            <button>Check out our open challenges page!</button>
          </div>
        </a>
        {/* <div className='dates'>
          {this.dates}
    </div> */}
      </div>
    );
  }
}

Schedule.propTypes = propTypes;
Schedule.defaultProps = defaultProps;

export default Schedule;

import React from "react";
import "./index.scss";

export function SupportBox() {
  return (
    <div className="SupportBox-container">
      <h1>Your support counts for innovation!</h1>
      <div>
        <a
          target="_blank"
          href="https://redirectioneaza.ro/asociatia-tech-lounge"
        >
          <button>3.5% Tax Contribution</button>
        </a>
        <a target="_blank" href="https://buy.stripe.com/8wMeX31XG0ifgh2001">
          <button>Make a donation</button>
        </a>
      </div>
    </div>
  );
}

/**
 * Mentors listing for the mentors page
 */

import React, { Component } from 'react'

import PropTypes from 'prop-types'

import CitySelector from 'components/Common/CitySelector'
import Mentor from 'components/Common/Person'

import './index.scss'

class MentorsList extends Component {
  static propTypes = {
    data: PropTypes.array
  }

  static defaultProps = {
    data: []
  }

  state = {
    city: '',
    secondaryCategory: 'Mentors' // 'Mentors' | 'Juries' | 'Speakers'
  }

  componentDidMount() {
    setTimeout(() => {
    if (window.location.href.includes('juries')) {
      this.setState({secondaryCategory: 'Juries'})
    }
    if (window.location.href.includes('speakers')) {
      this.setState({secondaryCategory: 'Speakers'})
    }
    if (window.location.href.includes('mentors')) {
      this.setState({secondaryCategory: 'Mentors'})
    }
    }, 500)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.city !== '') {
      this.setState({ ...this.state, city: nextProps.city })
    }
  }

  get filters() {
    return (
      <React.Fragment>
        <CitySelector
          options={this.props.data
            .filter(x => x.city !== 'Homepage' && x.city !== 'Sibiu')
            .map(x => x.city)}
          selected={this.state.city}
          onClick={city => this.setState({ ...this.state, city })}
        />
        <CitySelector
          sortOptions={false}
          options={['Mentors', 'Juries', 'Speakers']}
          selected={this.state.secondaryCategory}
          onClick={secondaryCategory => this.setState({ secondaryCategory })}
        />
      </React.Fragment>
    )
  }

  get mentors() {
    let { city } = this.state
    let { data } = this.props
    data = data
      .filter(x => x.city === city && x.city !== 'Homepage')
      .filter(x => {
        if (x.comments) {
          return x.comments.includes(this.state.secondaryCategory.slice(0, 3))
        } else {
          return this.state.secondaryCategory === 'Mentors'
        }
      })

    return data.map((x, i) => <Mentor data={x} key={i} />)
  }

  render() {
    if (this.props.data.length === 0) {
      return null
    }

    return (
      <div className='MentorsList'>
        <div className='header'>
          <div className='text'>
            <p>
              Top mentors from the startup ecosystem and ICT industry keep open lines of dialogue and feedback throughout the program and beyond, helping teams to align disruptive technologies with well-specified needs.
            </p>
          </div>
          <div className='filters'>
            {this.filters}
          </div>
        </div>
        <div className='list'>
          {this.mentors}
        </div>
      </div>
    )
  }
}

export default MentorsList

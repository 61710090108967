/**
 * Location service
 */

import axios from 'axios'

function getCity (lat, lng) {
  return axios.get(
    'https://maps.googleapis.com/maps/api/geocode/json?latlng=' +
      lat +
      ',' +
      lng +
      '&language=en&key=AIzaSyAWRHqWWB52Xgj9NstZtKaAIUhLLrnCST4'
  )
}

function extractCity (data) {
  let localities = data.data.results[1].address_components.filter(z =>
    z.types.includes('locality')
  )

  console.log(localities)

  if (localities.length > 0) {
    if (localities[0].short_name === 'București') {
      return 'Bucharest'
    } else {
      return localities[0].short_name
    }
  } else {
    return 'Bucharest'
  }
}

export default function (callback) {
  axios
    .post(
      'https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyAWRHqWWB52Xgj9NstZtKaAIUhLLrnCST4'
    )
    .then(x => {
      let { lat, lng } = x.data.location

      return getCity(lat, lng)
    })
    .then(extractCity)
    .then(callback)
    .catch(e => {
      console.error(e)
      callback('')
    })
}

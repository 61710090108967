import './index.scss'
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import LazyLoad from 'react-lazyload'

class Partner extends Component {
  static propTypes = {
    name: PropTypes.string,
    url: PropTypes.string,
    image: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }
  static defaultProps = {
    name: '',
    url: '',
    image: '',
    width: '',
    height: ''
  }

  render() {
    const { name, url, image } = this.props

    if (name.includes('_blank')) {
      return <div className='Partner' />
    }

    return (
      <a className='Partner' href={url} target='_blank'>
        <LazyLoad height={50}>
          <img src={image} alt={name} />
        </LazyLoad>
      </a>
    )
  }
}

export default Partner

/**
 * Person element
 */

import React, { Component } from "react";
import PropTypes from "prop-types";

import Lazyload from "react-lazyload";

import defaultPhoto from "./defaultPhoto.svg";

import linkedinLogo from "./linkedin.svg";

import "./index.scss";

class Person extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      isHoveredOver: false
    };
  }

  get className() {
    let { grayscale } = this.props;
    let className = "Person";

    if (grayscale) {
      className += " grayscale";
    }

    return className;
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ error: true });
    setTimeout((_) => this.setState({ error: false }), 100);
  }

  get linkedin() {
    const { linkedin } = this.props.data;

    if (linkedin === "") {
      return null;
    }

    return (
      <a className="linkedin" target="_blank" href={linkedin}>
        <img src={linkedinLogo} alt="linkedinLogo" />
      </a>
    );
  }

  get description() {
    const { description } = this.props.data;
    const { isHoveredOver } = this.state;

    try {
      if (isHoveredOver) {
        return description;
      } else {
        return description.length > 100
          ? `${description.slice(0, 100)}...`
          : description;
      }
    } catch (e) {
      console.error(`❌ Error getting description: ${e.message}`);
      console.error(e);
      return "";
    }
  }

  get position() {
    const { position } = this.props.data;
    const { isHoveredOver } = this.state;

    try {
      if (isHoveredOver) {
        return position;
      } else {
        return position.length > 100
          ? `${position.slice(0, 100)}...`
          : position;
      }
    } catch (e) {
      console.error(`❌ Error getting position: ${e.message}`);
      console.error(e);
      return "";
    }
  }

  render() {
    let { name, role, photo, position } = this.props.data;
    let { error } = this.state;
    let src = photo;

    if (error) {
      src = defaultPhoto;
    }

    return (
      <div
        className={this.className}
        onMouseEnter={() => this.setState({ isHoveredOver: true })}
        onMouseLeave={() => this.setState({ isHoveredOver: false })}
      >
        {this.linkedin}
        <Lazyload>
          <img
            src={src}
            onError={(_) => this.setState({ error: true })}
            alt=""
          />
        </Lazyload>
        <h2> {name} </h2>
        <p> {!position ? role : this.position} </p>
        <p> {this.description} </p>
      </div>
    );
  }
}

Person.propTypes = {
  data: PropTypes.object,
  grayscale: PropTypes.bool
};

Person.defaultProps = {
  data: {
    name: "",
    position: "",
    photo: ""
  },
  grayscale: false
};

export default Person;

/**
 * Header component
 *
 * Changes / sets extra options based on props
 */

import React, { Component } from "react";
import PropTypes from "prop-types";
import defaultBg from "./img/defaultBg2021.jpg";

import "./index.scss";

class Header extends Component {
  render() {
    let { title, image, titleImage } = this.props;

    if (
      image === "" ||
      image.includes("http://") ||
      image.includes("https://")
    ) {
      image = defaultBg;
    }

    let style = {
      backgroundImage: "url('" + image + "')",
    };

    if (titleImage !== "") {
      titleImage = <img src={titleImage} alt="" />;
    } else {
      titleImage = null;
    }

    if (title === "") {
      title = (
        <h2 className="title">
          Empowering <span> tech founders </span>
          <br />
          <span>
            <p className="subtitle">
              to build innovative digital solutions and the next global
              companies.{" "}
            </p>{" "}
          </span>
        </h2>
      );
    } else {
      title = (
        <h2 className="title">
          {titleImage}
          {title}
        </h2>
      );
    }

    return (
      <div className="Header" style={style}>
        {title}
        {/* <RegButton /> */}
      </div>
    );
  }
}

Header.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  titleImage: PropTypes.string,
};

Header.defaultProps = {
  title: "",
  image: defaultBg,
  titleImage: "",
};

export default Header;

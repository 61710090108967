/**
 * Who we are listing
 */

import React, { Component } from "react";
import PropTypes from "prop-types";

import CitySelector from "components/Common/CitySelector";
import Person from "components/Common/Person";

import "./index.scss";

function unique(list) {
  return list.filter((x, i) => list.indexOf(x) === i);
}

function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

class WhoWeAre extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: ""
    };
  }

  componentDidMount() {
    const url = window.location.href;
    const hasArgument = url.split("/").length === 5;
    const isValidArgument = hasArgument && url.split("/")[4].length !== 0;

    if (hasArgument && isValidArgument) {
      const argument = url.split("/")[4];
      this.setState({ filter: capitalize(argument) });
    }
  }

  componentWillReceiveProps(nextProps) {
    const url = window.location.href;
    const hasArgument = url.split("/").length === 5;

    if (hasArgument) {
      return;
    }

    if (nextProps.data.length > 0) {
      if (nextProps.city !== "") {
        this.setState({ ...this.state, filter: nextProps.city });
      } else {
        this.setState({ ...this.state, filter: "Board" });
      }
    }
  }

  getFilters() {
    let { data } = this.props;
    let { filter } = this.state;

    return (
      <CitySelector
        options={unique(data.map((x) => x.city))}
        selected={filter}
        onClick={(filter) => this.setState({ ...this.state, filter })}
      />
    );
  }

  getPeople() {
    let { filter } = this.state;
    let { data } = this.props;

    // Generate list of lists w/ breakAfter
    let list = [];
    let tempList = [];

    data
      .filter((x) => x.city === filter)
      .forEach((x) => {
        tempList.push(x);
        if (x.breakAfter === "TRUE") {
          list.push(tempList);
          tempList = [];
        }
      });

    if (tempList.length !== 0) {
      list.push(tempList);
    }

    return list.map((x, i) => (
      <div key={i + filter} className="list">
        {x.map((z, i) => (
          <Person key={i} data={z} grayscale />
        ))}
      </div>
    ));
  }

  render() {
    const { data } = this.props;
    if (data.length === 0) {
      return null;
    }

    return (
      <div className="WhoWeAre">
        <header>
          <div className="text">
            <h1> The teams behind the magic </h1>
          </div>
          <ul className="filter">{this.getFilters()}</ul>
        </header>
        {this.getPeople()}
      </div>
    );
  }
}

WhoWeAre.propTypes = {
  data: PropTypes.array
};

WhoWeAre.defaultProps = {
  data: []
};

export default WhoWeAre;

/**
 * Footer component
 */

import React, { Component } from 'react'

import Partner from 'components/Common/Partner'
import CitySelector from 'components/Common/CitySelector'

import './index.scss'

function unique(list) {
  return list.filter((x, i) => list.indexOf(x) === i)
}

class Partners extends Component {
  constructor(props) {
    super(props)

    this.state = {
      city: ''
    }
  }
  componentWillReceiveProps(nextProps) {
    let { city, data } = nextProps

    if (!(data instanceof Array)) {
      data = []
    }

    if (city !== '') {
      this.setState({ ...this.state, city })
    } else {
      this.setState({
        ...this.state,
        city: data.map(x => x.city).filter(x => x !== '')[0]
      })
    }
  }

  toggleCity = e => {
    let city = e.currentTarget.textContent
    this.setState({ ...this.state, city })
  }

  getCities = list => {
    let { city } = this.state
    list = unique(list.map(x => x.city)).sort()

    return (
      <CitySelector
        options={list}
        selected={city}
        onClick={city => this.setState({ ...this.state, city })}
      />
    )
  }

  getRegionalPartners = list => {
    let { city } = this.state

    return list
      .filter(x => typeof x.city !== 'undefined' && x.city.indexOf(city) >= 0)
      .map((x, i) => <Partner key={i} {...x} />)
  }

  getStyle = elem => {
    let style = {}

    if (elem.hasOwnProperty('height')) {
      style.height = elem.height + 'px'
    }

    if (elem.hasOwnProperty('width')) {
      style.width = elem.width + 'px'
    }

    if (elem.hasOwnProperty('marginLeft')) {
      style.marginLeft = elem.marginLeft + 'px'
    }

    return style
  }

  getPartnerBlock = (name, list) => {
    let currentList = []
    let partners = []


    list.forEach((x, i, l) => {
      const partner = <Partner key={i} {...x} />

      currentList.push(partner)

      const needToSeparateAfter = x.hasOwnProperty('separateAfter') && x.separateAfter.includes('y')
      const isLastElemOfList = i === l.length - 1

      console.log(i, l, l.length)

      if (needToSeparateAfter || isLastElemOfList) {
        partners.push(currentList)
        currentList = []
      }

    })

    if (partners.length === 0) {
      return null
    }

    return partners.map((pList, key) =>
      <div className='partnerBlock' key={key}>
        {key === 0 && <h2> {name} </h2>}
        <div className={`list ${pList.length === 1 && 'centered'}`}>
          {pList}
        </div>
      </div>
    )
  }

  render() {
    const { data } = this.props
    const regionalPartners = data.filter(
      x => x.category === 'Partners' && x.city !== ''
    )

    if (data.length === 0) {
      return null
    }

    return (
      <div className='Partners'>
        {/* {this.getPartnerBlock(
          'A program by',
          data.filter(x => x.category === 'A project by')
        )} */}
        {/* {this.getPartnerBlock(
          'Main partners',
          data.filter(x => x.category === 'Main Partners')
        )} */}
        {/* {this.getPartnerBlock(
          'Strategic Partner',
          data.filter(x => x.category === 'Strategic Partner')
        )} */}
        <div className='regionalPartners partnerBlock'>
          <div className='title'>
            <h2> Regional communities </h2>
            {this.getCities(regionalPartners)}
          </div>
          <div className='list'>
            {this.getRegionalPartners(regionalPartners)}
          </div>
        </div>
        {/* {this.getPartnerBlock(
          'Academic Partners',
          data.filter(x => x.category === 'Academic Partners')
        )} */}
        {/* {this.getPartnerBlock(
          'Implemented together with',
          data.filter(x => x.category === 'Implemented together with')
        )}
        {this.getPartnerBlock(
          'With the help of',
          data.filter(x => x.category === 'With the help of')
        )} */}
      </div>
    )
  }
}

export default Partners

/**
 * Main component for the Contact modal
 */

import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Modal from 'rodal'

import 'rodal/lib/rodal.css'
import './index.scss'

const propTypes = {
  data: PropTypes.object,
  visible: PropTypes.bool,
  sent: PropTypes.bool,
  onClose: PropTypes.func,
  onSend: PropTypes.func
}
const defaultProps = {
  onClose: _ => {},
  onSend: _ => {}
}

class Contact extends Component {
  render () {
    let {visible, onClose, onSend, sent} = this.props
    let className = 'Contact' + (sent ? ' sent' : '')

    return (
      <Modal visible={visible}
        onClose={onClose}
        width={300}
        height={300}
        className={className}>
        <h2> Let's get in touch! </h2>
        <form method='POST'
          action='https://docs.google.com/forms/d/e/1FAIpQLScD873jdzYbxlAWkNcA1-IdVRQUjRlLjDqsuujLRwLjNNsNQA/formResponse'
          target='myframe'>
          <label> Your name </label>
          <input
            name='entry.16950476'
            type='text' />
          <label> Your e-mail address </label>
          <input
            name='entry.1576075203'
            type='text' />
          <label> Your message </label>
          <textarea name='entry.1535891092' />
          <button onClick={onSend}> Send </button>
        </form>
        <iframe name='myframe' title='frame' />
      </Modal>
    )
  }
}

Contact.propTypes = propTypes
Contact.defaultProps = defaultProps
export default Contact

/**
 * Tracks listing
 */

import PropTypes from "prop-types";
import React, { Component } from "react";

// import Partner from "components/Common/Partner";

import "./index.scss";

import agriculture from "./img/newlogos/Agriculture.svg";
import blockchain from "./img/newlogos/Blockchain.svg";
import cybersecurity from "./img/newlogos/Cybersecurity.svg";
import devtools from "./img/newlogos/DevTools.svg";
import digitalhealth from "./img/newlogos/DigitalHealth.svg";
import lifestyle from "./img/newlogos/Lifestyle.svg";
import retail from "./img/newlogos/Retail.svg";
import smartcity from "./img/newlogos/SmartCity.svg";
import sustainability from "./img/newlogos/Sustainability.svg";

import profiteam from "./img/profiteam.svg";
import studentteam from "./img/studentteam.svg";
import uniteam from "./img/uniteam.svg";

import cybersecPartner from "./img/partners/eviden.png";
import fintechPartner from "./img/partners/brd.png";
import retailPartner from "./img/partners/carrefour.png";
import orange from "./img/partners/orange.png";
import agriculturePartner from "./img/partners/raf.png";

import arobs from "./img/partners/arobs.png";
import blockchain2022 from "./img/partners/blockchain.png";
import devtoolsPartner from "./img/partners/genezio.png";

const tracks = [
  {
    title: "Agriculture",
    photo: agriculture,
    partnerName: "RAF",
    partnerImg: agriculturePartner,
    text: "Take up the challenge of making agriculture more attractive for young professionals and entrepreneurs, more efficient for farmers, more profitable in a changing economy, more sustainable and ecological.",
  },
  {
    title: "Blockchain",
    photo: blockchain,
    partnerName: "Elrond|Simple Capital Labs",
    // partnerImg: blockchain2022,
    text: "Create cutting edge solutions with a strong blockchain core solving critical business needs, from health to supply chain management, and have them showcased on this special track.",
  },
  {
    title: "Cybersecurity",
    photo: cybersecurity,
    partnerName: "Eviden",
    partnerImg: cybersecPartner,
    text: "Create solutions for a safer digital world, empowering users to explore in confidence the previously uncharted and push the boundaries of knowledge and experience, in full awareness of risks and defense measures.",
  },
  {
    title: "DevTools",
    photo: devtools,
    partnerName: "Genezio",
    partnerImg: devtoolsPartner,
    text: "If you are passionate about making life easier for software and hardware developers and increase their productivity to more easily create, share, reuse or otherwise make more creative use of code, this track is for you.",
  },
  // {
  //   title: "Digital Commerce",
  //   photo: digitalcommerce,
  //   partnerName: "VTEX",
  //   partnerImg: vtex,
  //   text:
  //     "Revolutionize retail through new software tools and integration."
  // },
  // {
  //   title: "Digital Commerce",
  //   photo: digitalcommerce,
  //   partnerName: "VTEX",
  //   partnerImg: vtex,
  //   text: "Revolutionize retail through new software tools and integration.",
  // },
  {
    title: "DigitalHealth",
    photo: digitalhealth,
    partnerName: "",
    text: "Build digital solutions (software and/or hardware) that will help medical professionals, patients, caretakers and medical institutions to build the future of healthcare.",
  },
  {
    title: "Lifestyle",
    photo: lifestyle,
    partnerName: "",
    text: "Help people take full advantage of current technologies to increase self-knowledge, hack their daily routines and take control over their lifestyles.",
  },
  {
    title: "Retail & E-commerce",
    photo: retail,
    partnerName: "Carrefour",
    partnerImg: retailPartner,
    text: "Revolutionize retail by merging mobile applications, IoT sensors and devices, cloud service and powerful data analytics tools for novel customer-business interaction.",
  },
  {
    title: "Smart City & Industry 4.0",
    photo: smartcity,
    partnerName: "AROBS",
    partnerImg: arobs,
    text: "Boost urban experiences and shape inspiriting environments through rich information flows and secure feedback loops, synchronizing rhythms of daily life, business, and travel into a city-wide performance.",
  },
  {
    title: "Sustainability",
    photo: sustainability,
    partnerName: "BRD",
    partnerImg: fintechPartner,
    text: "Sustainability is becoming a more and more important subject, pushed on the public agenda by authorities, legislation, companies, but also by more and more citizens, and NGOs. The achievement of a balanced economic, social and environmental development (as expressed in the UN Agenda 2030 for Sustainable Development) is one of the major challenges humanity is facing today. Yet, challenges come with a lot of opportunity points.",
  },
];

const categories = [
  {
    photo: studentteam,
    title: "Student driven team",
    text: "Innovation Labs is a University centered program. Choose this category if you are a team of students keen to develop entrepreneurial thinking and to learn the basics of start-up creation.",
    partnerName: "RAF",
    partnerImg: agriculturePartner,
  },
  {
    photo: uniteam,
    title: "University research spin-off",
    text: "Innovation Labs aims to foster effective technology transfer. Choose this category if you are a team with a proven research idea (PhD, PostDoc) and you are looking for ways to get it to market. Ideally, the author of such research would be the team lead. ",
    partnerName: "Orange",
    partnerImg: orange,
  },
  {
    photo: profiteam,
    title: "Senior team",
    text: "Innovation Labs continuously strives to grow the start-up ecosystem at large. Choose this category if you are a professional with experience in building and managing companies looking to test out a new idea. To provide the best value for the ecosystem, we believe fresh talent should also be employed, so your team must contain at least one student",
    partnerName: "",
  },
];

class Tracks extends Component {
  static propTypes = {
    partners: PropTypes.arrayOf(PropTypes.object),
  };
  static defaultProps = {
    partners: [],
  };

  getPartner = (track) => {
    try {
      // const { partners } = this.props;
      // let partner;

      // let { partnerName } = track;

      console.log("✅ Trying to get partner");
      // partner = partners.find((x) => x.name === partnerName);

      if (typeof track.partnerImg === "undefined") {
        return null;
      }

      // let logo;
      // if ( partnerName === "Atos" || partnerName === "Carrefour" || partnerName === "BRD" || partnerName === "OMV Petron")
      //   logo = <img src = {track.partnerImg}/>
      // else
      //   logo = <Partner {...partner} />
      return (
        <div style={{ textAlign: "left" }} className="button">
          <span> powered by </span>
          <img
            alt=""
            style={{ marginLeft: "20px" }}
            className={track.partnerName}
            src={track.partnerImg}
          />
        </div>
      );
    } catch (e) {
      return null;
    }
  };

  getTrack = (track, key) => (
    <div className="element" key={key}>
      <img className="trackLogo" src={track.photo} alt="" />
      <div className="content">
        <h2> {track.title} </h2>
        <p>{track.text}</p>
        {this.getPartner(track)}
      </div>
    </div>
  );

  render() {
    return (
      <div className="Tracks">
        <h1>
          How to find your team category & business track <br /> for a
          game-changing solution
        </h1>
        <br />
        <p style={{ fontSize: "1.2rem" }}>
          Step 1: Select your team type from <br /> the following 3 categories:{" "}
        </p>
        <div className="list">
          {categories.map((category, i) => this.getTrack(category, i))}
        </div>
        <br />
        <p style={{ fontSize: "1.2rem" }}>
          Step 2: Pick one of the 9 tracks to align your idea
        </p>
        <p style={{ fontSize: "0.9rem" }}>
          with savvy mentors, bleeding edge technologies, and current industry &
          business opportunity
        </p>

        <div className="list">
          {tracks.map((track, i) => this.getTrack(track, i))}
        </div>
      </div>
    );
  }
}

export default Tracks;

/**
 * Teams listing routing component
 */

import React, { Component, Fragment } from 'react'

import Header from 'components/Common/Header'

// import Showcase from 'components/Teams/Showcase'
import Listing from 'components/Teams/Listing'
// import MakeYourOwn from 'components/Teams/MakeYourOwn'

import image from './img/headerImage.jpg'
import TeamsShowcase2 from '../../components/Home/TeamsShowcase2'

import axios from 'axios'

class Teams extends Component {
  state = {
    teams: [],
    error: false,
  }

  componentWillMount = async () => {
    let teams

    try {
      teams = await axios.get('/api/teams/')
    } catch (e) {
      this.setState({ error: true })
    }

    this.setState({ teams: teams.data })
  }

  render() {
    const { error, teams } = this.state

    const content = error ? (
      <Fragment>
        <h2> An error has occurred loading teams. Try again a bit later</h2>
      </Fragment>
    ) : (
      <Fragment>
        {/* <Showcase data={teams.filter(x => x.best && x.best !== '')} /> */}
        <Listing teams={teams} {...this.props.match.params} />
      </Fragment>
    )

    return (
      <Fragment>
        <Header title='Teams' image={image} />
        <TeamsShowcase2 />

        {content}
      </Fragment>
    )
  }
}

export default Teams

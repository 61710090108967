/**
 * Contact form modal
 */

import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Main from './components/Main'

const propTypes = {
  onClose: PropTypes.func,
  visible: PropTypes.bool
}
const defaultProps = {
  onClose: _ => {},
  visible: true
}

class ContactModal extends Component {
  constructor (props) {
    super(props)

    this.state = {
      sent: false
    }
  }

  onSend = _ => {
    this.setState({...this.state, sent: true})
    setTimeout(
      _ => {
        this.props.onClose()
        this.setState({...this.state, sent: false})
      },
      2000
    )
  }

  render () {
    let {visible, onClose} = this.props
    let {sent, data} = this.state

    return (
      <Main visible={visible}
        sent={sent}
        onSend={this.onSend}
        onClose={onClose}
        data={data} />
    )
  }
}

ContactModal.propTypes = propTypes
ContactModal.defaultProps = defaultProps
export default ContactModal

/**
 * Mentors listing
 */

import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Mentor from 'components/Common/Person'
import { Link } from 'react-router-dom'

import './index.scss'

class RegisterNow extends Component {
  static propTypes = {
    data: PropTypes.array
  }

  static defaultProps = {
    data: []
  }

  render () {
    const { data } = this.props

    if (data.length === 0) {
      return null
    }

    return (
      <div className='MentorsList'>
        <div className='header'>
          <div className='text'>
            <h2> Mentors for real </h2>
            <p>
              Top mentors unfold your horizons in ideation, design, technical and business choice. Hop on a guided adventure into your future!
            </p>
          </div>
          <Link to='/mentors'>
            <button> see all &gt; </button>
          </Link>
        </div>
        <div className='list'>
          {data.map((x, i) => <Mentor data={x} key={i} />)}
        </div>
      </div>
    )
  }
}

RegisterNow.propTypes = {
  data: PropTypes.array
}

RegisterNow.defaultProps = {
  data: []
}

export default RegisterNow

/**
 * Team listing routing component
 */

import React, { Component, Fragment } from "react";

import Header from "components/Common/Header";
// import Showcase from 'components/Teams/Showcase'
import Listing from "components/Teams/Listing";
import AboutBox from "components/Team/AboutBox";
// import MediaBox from 'components/Team/Media'
import image from "../Teams/img/headerImage.jpg";

import axios from "axios";

class Team extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      teams: [],
      error: false,
    };
  }

  async componentWillReceiveProps() {
    let teamName = window.location.href.split("/").slice(-1);

    axios
      .get("/api/team/" + teamName)
      .then((x) => this.setState({ ...this.state, data: x.data }));

    let teams;

    try {
      teams = await axios.get("/api/teams/");
    } catch (e) {
      if (e.response && e.response.status === 404) {
        window.location.href = "/404";
      }
      this.setState({ error: true });
    }

    this.setState({ teams: teams.data });
  }

  async componentWillMount() {
    let teamName = window.location.href.split("/").slice(-1);
    console.log(teamName);

    axios
      .get("/api/team/" + teamName)
      .then((x) => this.setState({ ...this.state, data: x.data }))
      .catch((e) => {
        if (e.response && e.response.status === 404) {
          window.location.href = "/404";
        }
        this.setState({ error: true });
      });

    let teams;

    try {
      teams = await axios.get("/api/teams/");
    } catch (e) {
      if (e.response && e.response.status === 404) {
        window.location.href = "/404";
      }
      this.setState({ error: true });
    }

    this.setState({ teams: teams.data });
  }

  render() {
    const { teamName } = this.props.match.params;
    const { data } = this.state;
    const { profileImage, logo } = data;

    console.log("Team level: ");
    console.log(data);
    console.log(teamName);
    console.log(profileImage);

    // <MediaBox />

    const { error, teams } = this.state;

    const content = error ? (
      <Fragment>
        <h2> An error has occured loading teams. Try again a bit later</h2>
      </Fragment>
    ) : (
      <Fragment>
        {/* <Showcase data={teams.filter(x => x.best && x.best !== '')} /> */}
        <Listing teams={teams} {...this.props.match.params} />
      </Fragment>
    );

    return (
      <div>
        <Header title={teamName} titleImage={logo} image={profileImage} />
        <AboutBox data={data} />
        <Fragment>
          <Header title="Teams" image={image} />
          {content}
        </Fragment>
      </div>
    );
  }
}

export default Team;

/**
 * Main routes index component
 */

import React, { Component } from "react"

import {
	BrowserRouter as Router,
	Route,
	Redirect,
	Switch,
} from "react-router-dom"

import Container from "components/Container"
import Home from "routes/Home"
import About from "routes/About"
import Mentors from "routes/Mentors"
import Teams from "routes/Teams"
import Team from "routes/Team"
import Tech from "routes/Tech"
import WhoWeAre from "routes/WhoWeAre"
import Collaborators from "routes/Collaborators"
import Communities from "routes/Communities"

import NotFound from "routes/NotFound"

class MainRouter extends Component {
	render() {
		return (
			<Router>
				<Container>
					<Switch>
						<Route exact path="/" component={Home} />
						<Route exact path="/about" component={About} />
						<Route exact path="/program" component={About} />
						<Route exact path="/mentors" component={Mentors} />
						<Route
							exact
							path="/mentors/:city/"
							component={Mentors}
						/>
						<Route exact path="/speakers" component={Mentors} />
						<Route
							exact
							path="/speakers/:city/"
							component={Mentors}
						/>
						<Route exact path="/juries" component={Mentors} />
						<Route
							exact
							path="/juries/:city/"
							component={Mentors}
						/>
						<Route exact path="/teams" component={Teams} />
						<Route
							exact
							path="/teams/:year/:city/"
							component={Teams}
						/>
						<Route
							exact
							path="/teams/:year/:city/:track/"
							component={Teams}
						/>
						<Route exact path="/tech" component={Tech} />
						<Route
							exact
							path="/communities"
							component={Communities}
						/>
						<Route exact path="/whoweare" component={WhoWeAre} />
						<Route
							exact
							path="/whoweare/:category"
							component={WhoWeAre}
						/>
						<Route
							exact
							path="/collaborators"
							component={Collaborators}
						/>
						<Route
							path="/openchallanges"
							component={() => {
								window.location.href =
									"https://bit.ly/IL_open_challenge_2022"
								return null
							}}
						/>
						<Route
							path="/openchallenges"
							component={() => {
								window.location.href =
									"https://bit.ly/IL_open_challenge_2022"
								return null
							}}
						/>
						<Route
							path="/2023_FastTrack_B"
							component={() => {
								window.location.href =
									"https://docs.google.com/forms/d/e/1FAIpQLSf024trlQbTbPPuD5wnOqSBu25tmhdCfV6BwseH6CRAWRJMVg/viewform?usp=sf_link"
								return null
							}}
						/>
						<Route
							exact
							path="/collaborators/:category"
							component={Collaborators}
						/>
						<Route exact path="/404" component={NotFound} />
						<Route exact path="/teams/:teamName" component={Team} />
						<Redirect to="/404" />
					</Switch>
				</Container>
			</Router>
		)
	}
}

export default MainRouter

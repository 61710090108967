/**
 * Team Box
 */

import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Lazyload from 'react-lazyload'

import './index.scss'

import iot from './img/iot.svg'
import mobile from './img/mobile.svg'
import energy from './img/energy.svg'
import agriculture from './img/agriculture.svg'
import cities from './img/cities.svg'
import cybersecurity from './img/cybersecurity.svg'
import health from './img/health.svg'
import retail from './img/retail.svg'
import team from './img/team.svg'

class TeamBox extends Component {
  static propTypes = {
    data: PropTypes.shape({
      name: PropTypes.string,
      best: PropTypes.string,
      track: PropTypes.string,
      logo: PropTypes.string,
      profileImage: PropTypes.string
    })
  }

  static defaultProps = {
    data: {
      name: '',
      best: '',
      track: '',
      logo: '',
      profileImage: ''
    }
  }

  state = { error: false }

  componentWillReceiveProps () {
    this.setState({ ...this.state, error: false })
  }

  setDefaultLogo = () => {
    this.setState({ ...this.state, error: true })
  }

  getTrack () {
    let image
    let track = this.props.data.track ? this.props.data.track.trim() : ''

    switch (track) {
      case 'IoT':
        image = iot
        break
      case 'Energy':
        image = energy
        break
      case 'Agriculture':
        image = agriculture
        break
      case 'Health & Lifestyle':
        image = health
        break
      case 'Cyber Security':
        image = cybersecurity
        break
      case 'Mobile':
        image = mobile
        break
      case 'Smart Cities':
        image = cities
        break
      case 'Retail':
        image = retail
        break
      default:
        image = ''
    }

    if (image === '') {
      return null
    }

    return (
      <div className='track'>
        <Lazyload>
          <img src={image} alt='' />
        </Lazyload>
      </div>
    )
  }

  getLogo () {
    const { logo } = this.props.data

    if (this.state.error) {
      return team
    } else {
      return logo
    }
  }

  getBest () {
    let { data } = this.props

    if (data.best && data.best !== '') {
      if (data.best.replace('Homepage', '').trim().length === 0) {
        return null
      } else {
        return <h2 className='best'>{data.best.replace(', Homepage', '')}</h2>
      }
    } else {
      return null
    }
  }

  render () {
    const { profileImage, name } = this.props.data

    return (
      <div className='TeamBox'>
        <Lazyload height={150}>
          <img className='bg' src={profileImage} alt='' />
        </Lazyload>
        {this.getBest()}
        <div className='title'>
          <Lazyload height={50}>
            <img
              src={this.getLogo()}
              onError={this.setDefaultLogo}
              className='logo'
              alt=''
            />
          </Lazyload>
          <h2> {name} </h2>
        </div>
        {this.getTrack()}
      </div>
    )
  }
}

export default TeamBox

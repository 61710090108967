/**
 * About box
 */

import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './index.scss'

import clock from 'components/Teams/Listing/img/clock.svg'
import location from 'components/Teams/Listing/img/location.svg'

import facebook from './img/facebook.svg'
import globe from './img/globe.svg'
import mail from './img/mail.svg'
import twitter from './img/twitter.svg'

import iot from 'components/Common/TeamBox/img/iot.svg'
import mobile from 'components/Common/TeamBox/img/mobile.svg'
import energy from 'components/Common/TeamBox/img/energy.svg'
import agriculture from 'components/Common/TeamBox/img/agriculture.svg'
import cities from 'components/Common/TeamBox/img/cities.svg'
import cybersecurity from 'components/Common/TeamBox/img/cybersecurity.svg'
import health from 'components/Common/TeamBox/img/health.svg'
import retail from 'components/Common/TeamBox/img/retail.svg'

class AboutBox extends Component {
  static propTypes = {
    data: PropTypes.object
  }

  static defaultProps = {
    data: {
      members: '',
      facebook: '',
      twitter: '',
      mail: ''
    }
  }

  componentWillReceiveProps () {
    this.setState({ ...this.state })
    console.log(this.props);
    console.log(this.state);

  }

  generateMemberList () {
    let { members } = this.props.data
    console.log(members);

    members = members.split(',').map(x => x.trim())

    if (members.length === 0) {
      return null
    }

    let membersList = members.map((x, i) => <li key={i}>{x}</li>)

    return [<h1 key={0}> Members </h1>, <ul key={1}>{membersList}</ul>]
  }

  generateContactEntry (url, photo, key) {
    let display = url

    if (url.length > 20) {
      display = url.split('/').slice(-1)[0]

      if (display.trim() === '') {
        display = url.split('/').slice(-2)[0]
      }
    }

    if (url.includes('@')) {
      url = 'mailto:' + url
    }

    return (
      <a href={url} target='_blank' key={key}>
        <li>
          <img src={photo} alt='' />
          {display}
        </li>
      </a>
    )
  }

  generateContactList () {
    let { data } = this.props
    let contactLinks = []

    if (data.facebook) {
      contactLinks.push(this.generateContactEntry(data.facebook, facebook, 1))
    }
    if (data.twitter) {
      contactLinks.push(this.generateContactEntry(data.twitter, twitter, 2))
    }
    if (data.mail) {
      contactLinks.push(this.generateContactEntry(data.mail, mail, 3))
    }
    if (data.website) {
      contactLinks.push(this.generateContactEntry(data.website, globe, 4))
    }

    if (contactLinks.length === 0) {
      return null
    }

    return [<h1 key={0}> Contact </h1>, <ul key={1}>{contactLinks}</ul>]
  }

  getVideo (url, title) {
    let id = url.split('/').slice(-1)[0]

    if (id.trim() === '') {
      id = url.split('/').slice(-2)[0]
    }

    if (url === '') {
      return null
    }

    return (
      <div>
        <h1> {title} </h1>
        <iframe
          title={id}
          src={'https://player.vimeo.com/video/' + id}
          width='300'
          height='169'
          frameBorder='0'
          allowFullScreen
        />
      </div>
    )
  }

  getTrack () {
    let image
    let track = this.props.data.track ? this.props.data.track.trim() : ''

    switch (track) {
      case 'IoT':
        image = iot
        break
      case 'Energy':
        image = energy
        break
      case 'Agriculture':
        image = agriculture
        break
      case 'Health & Lifestyle':
        image = health
        break
      case 'Cyber Security':
        image = cybersecurity
        break
      case 'Mobile':
        image = mobile
        break
      case 'Smart Cities':
        image = cities
        break
      case 'Retail':
        image = retail
        break
      default:
        image = ''
    }

    if (image === '') {
      return null
    }

    return <img src={image} alt='' />
  }

  render () {
    let { data } = this.props
    console.log(data);

    let isDataEmpty = Object.keys(data).length === 0
    let winner = null

    if (isDataEmpty) {
      return <h2> Loading </h2>
    }

    if (data.best !== '') {
      winner = (
        <div className='winnerCategory'>
          {data.best.replace(', Homepage', '')}
        </div>
      )
    }

    return (
      <div className='AboutBox'>
        <header>
          {winner}
          <div className='teamInfo'>
            <span>
              <img src={location} alt='' /> {data.city}
            </span>
            <span>
              <img src={clock} alt='' /> {data.year}
            </span>
            <div className='track'>
              {this.getTrack()}
              {data.track}
            </div>
          </div>
        </header>
        <div className='details'>
          <p>{data.description}</p>
          <div className='teamDetails'>
            {this.generateMemberList()}
            {this.generateContactList()}
          </div>
        </div>
        <div className='galleries'>

          {this.getVideo(data.hackathonVideo, 'Hackathon Pitch')}
          {this.getVideo(data.demoDayVideo, 'DemoDay Pitch')}
        </div>
      </div>
    )
  }
}

AboutBox.propTypes = {
  data: PropTypes.object
}

AboutBox.defaultProps = {
  data: {}
}

export default AboutBox

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import './index.scss'

import video from './10K_Feet.mp4'

class NotFound extends Component {
  render () {
    return (
      <div className='NotFound'>
        <div className='content'>
          <h1>404</h1>
          <h2> We couldn't find that page. </h2>
          <Link to='/'>
            <h3> Go to homepage ? </h3>
          </Link>
        </div>
        <video autoPlay loop>
          <source src={video} type='video/mp4' />
        </video>
      </div>
    )
  }
}

export default NotFound

/**
 * About routing component
 */

import React, { Component } from "react";

import Header from "components/Common/Header";
import StatsBox from "components/Common/StatsBox";

import ProgramTimeline from "components/About/ProgramTimeline";
import Tracks from "components/About/Tracks";

import axios from "axios";

import image from "./img/header.jpg";
import NewTimeline from "../../components/About/NewTimeline";

class About extends Component {
  state = {
    partners: [],
    schedule: []
  };

  componentWillMount() {

    window.scrollTo(0,0);

    axios
      .get("/api/partners/")
      .then((x) => this.setState({ ...this.state, partners: x.data }));

    axios
      .get("/api/schedule/")
      .then((x) => this.setState({ ...this.state, schedule: x.data }));
  }

  get schedule() {
    const { schedule } = this.state;

    if (schedule.length === 0) {
      return {
        Bucharest: "",
        Cluj: "",
        Iasi: "",
        Sibiu: "",
        Timisoara: ""
      };
    }

    return {
      Bucharest: schedule.find(
        (x) => x.city === "Bucharest" && x.title === "hackathon"
      ).date,
      Cluj: schedule.find((x) => x.city === "Cluj" && x.title === "hackathon")
        .date,
      Iasi: schedule.find((x) => x.city === "Iasi" && x.title === "hackathon")
        .date,
      Sibiu: schedule.find((x) => x.city === "Sibiu" && x.title === "hackathon")
        .date,
      Timisoara: schedule.find(
        (x) => x.city === "Timisoara" && x.title === "hackathon"
      ).date
    };
  }

  render() {
    const { partners } = this.state;

    return (
      <div>
        <Header title="Program" image={image} />
        <StatsBox
          title="The Innovation Labs experience:"
          subtitle="A 3-month mentoring & development program
          for young entrepreneurs and startup teams  "
          description="Choose your track, bid your idea, experience the Hackathon, get noticed, meet mentors, find partners, and push harder. Develop, grow, fine-tune, finance and expand your idea into a convincing MVP. It’s Demo Day: Pitch your final prototype in an electrifying event!"
        />
        <Tracks partners={partners} />
        <ProgramTimeline />
        <NewTimeline schedule={this.schedule} />
      </div>
    );
  }
}

export default About;

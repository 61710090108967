/**
 * Mentors routing component
 */

import React, { Component } from 'react'

import axios from 'axios'


import Header from 'components/Common/Header'

import MentorsList from 'components/Mentors/MentorsList'

import image from './img/header.jpg'

function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

class Mentors extends Component {
  state = {
    mentors: [],
    city: 'Bucharest',
    error: false
  }

  componentDidMount() {
    const url = window.location.href;
    const hasArgument = url.split('/').filter(x => x !== "").length === 4

if (hasArgument) {
  const city = url.split('/').filter(x => x !== "")[3]
  this.setState({city: capitalize(city)})
}
  }

  componentWillMount = async () => {
    let res

    try {
      res = await axios.get('/api/mentors/')
    } catch (e) {
      this.setState({ error: true })
    }
    this.setState({ mentors: res.data })
  }

  render() {
    const { mentors, city, error } = this.state

    const content = error
      ? <h2>An error has occurred loading mentors. Try again a bit later. </h2>
      : <MentorsList data={mentors} city={city} />

    return (
      <div>
        <Header title='Mentors & Juries' image={image} />
        {content}
      </div>
    )
  }
}

export default Mentors
